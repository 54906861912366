const shuffle = (array) => {
  let i = array.length,
  j = 0,
  temp;
  while (i--) {
    j = Math.floor(Math.random() * (i+1));
    // swap randomly chosen element with current element
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export { shuffle }; 